<template>
  <div id="backlog-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <!--<button class="btn btn-primary mb-5">Neue Hypothese hinzufügen</button>

    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div v-if="activeTheoryId" class="alert alert-success">
          <div class="alert-body">
            <h3>Deine aktuelle Theorie:</h3>
            <p class="lead mb-0">
              {{ activeTheory.description }}
            </p>
          </div>
        </div>
        <div v-else class="alert alert-danger">
          <div class="alert-body">
            <p class="mb-0">
              Du hast keine Theorie ausgewählt. Wähle eine bestehende Theorie, um deine Hypothesen daran auszurichten.
            </p>
          </div>
        </div>
      </div>
    </div>-->

    <ComingSoonBanner></ComingSoonBanner>
  </div>
</template>

<script>
// @ is an alias to /src
import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Hypotheses',
  components: {
    ComingSoonBanner,
  },
  data() {
    return {
    }
  },
  computed: {
    ideas() {
      return this.project.ideas
          .filter(idea => !this.activeTheoryId || idea.theoryId === this.activeTheoryId)
          .map(idea => {
        idea.node = this.project.netNodes.find(n => n.url === idea.nodeUrl);

        if (idea.scores) {
          let scores = idea.scores;
          if (Object.values(scores).filter(x => x === undefined).length === 0) {
            let effort = parseInt(scores.frontendEffort) + parseInt(scores.backendEffort) + parseInt(scores.conceptEffort) + parseInt(scores.otherEffort);
            let impact = parseInt(scores.visualContrast) + parseInt(scores.behaviourContrast) + parseInt(scores.patternsImpact) + parseInt(scores.trafficImpact);

            idea.effort = effort < 8 ? 'Low' : effort > 16 ? 'High' : 'Medium';
            idea.impact = impact < 8 ? 'Low' : impact > 16 ? 'High' : 'Medium';
            idea.scoring = Math.round((impact - effort) * 3.125 + 50);
          }
        } else {
          idea.scoring = "N/A";
        }
        return idea;
      });
    },
  },
  methods: {
  }
}
</script>
